import React from 'react'
import Button from './button'

import cn from './form.module.scss'

const Form = () => (
  <div className={cn.form}>
    <form
      name="contact"
      method="post"
      action="/success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className={cn.formGroup}>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" required />
      </div>
      <div className={cn.formGroup}>
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" required />
      </div>
      <div className={cn.formGroup}>
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="6" required />
      </div>
      <div className={cn.actions}>
        <Button success type="submit">
          Submit
        </Button>
        <Button type="reset" outline>
          Clear
        </Button>
      </div>
    </form>
  </div>
)

export default Form
