import React from 'react'
import Layout from '../components/layout'
import Form from '../components/form'
import { Link } from 'gatsby'

import cn from './contact.module.scss'

const Contact = () => (
  <Layout>
    <Form />
    <h4>
      <Link to="/" className={cn.link}>
        Home
      </Link>
    </h4>
  </Layout>
)

export default Contact
